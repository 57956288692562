import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'
import {Reservation} from '@wix/ambassador-table-reservations-v1-reservation/types'

type MessageType = 'email' | 'phone'

export const getMessageType = (reservee): MessageType => {
  if (reservee?.phone) {
    return 'phone'
  }

  if (reservee?.email) {
    return 'email'
  }

  return 'email'
}

export const getCalendarHref = (
  reservationLocation?: ReservationLocation,
  reservation?: Reservation,
) => {
  const dateFrom = getFormattedDate(reservation?.details?.startDate)
  const dateTo = getFormattedDate(reservation?.details?.endDate)

  return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${reservationLocation?.location?.name}&dates=${dateFrom}/${dateTo}&location=${reservationLocation?.location?.address?.formattedAddress}&ctz=${reservationLocation?.location?.timeZone}`
}

const getFormattedDate = (date?: Date | null) =>
  date ? removeMilliseconds(toISOStringWithoutSeparators(date)) : undefined

const toISOStringWithoutSeparators = (date: Date) =>
  new Date(date).toISOString().replace(/-/g, '').replace(/:/g, '')

const removeMilliseconds = (date: string) => `${date.substring(0, date.indexOf('.'))}Z`

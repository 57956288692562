import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings'

export type DesignStylesParams = {
  backgroundColor: StyleParamType.Color
  borderColor: StyleParamType.Color
  borderWidth: StyleParamType.Number
  borderRadius: StyleParamType.Number
  dividerWidth: StyleParamType.Number
  dividerColor: StyleParamType.Color
  titleFont: StyleParamType.Font
  titleColor: StyleParamType.Color
  subtitleFont: StyleParamType.Font
  subtitleColor: StyleParamType.Color
  bodyFont: StyleParamType.Font
  bodyColor: StyleParamType.Color
  linkFont: StyleParamType.Font
  linkColor: StyleParamType.Color
}

export const designStyleParams = createStylesParams<DesignStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  borderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  titleFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: (params) =>
      wixFontParam('Page-title', {
        htmlTag: 'h1',
        size: params.isMobile ? 28 : 32,
      })(params),
  },
  titleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subtitleFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: (params) =>
      wixFontParam('Body-M', {
        htmlTag: 'p',
        size: params.isMobile ? 16 : 20,
      })(params),
  },
  subtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  bodyFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {size: 16}),
  },
  bodyColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  linkFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      style: {underline: true, bold: false, italic: false},
    }),
  },
  linkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
})

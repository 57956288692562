import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

import {BreadcrumbsAlignment, TextAlignment} from '../../../../../types/createStylesParams'

export type LayoutSettingsParams = {
  textAlignment: SettingsParamType.String
  breadcrumbsAlignment: SettingsParamType.String
}

export const layoutSettingsParams = createSettingsParams<LayoutSettingsParams>({
  breadcrumbsAlignment: {
    type: SettingsParamType.String,
    inheritDesktop: false,
    getDefaultValue: () => BreadcrumbsAlignment.Default,
  },
  textAlignment: {
    type: SettingsParamType.String,
    inheritDesktop: false,
    getDefaultValue: () => TextAlignment.Center,
  },
})
